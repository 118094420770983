import React from "react";
import { InfoEmail, InfoPhone } from "./Emails";
import { SocialIcons } from "./SocialIcons";
import Logo from "../svgs/Logo.svg";

export function Footer() {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__row">
          <Logo className="footer__logo" />
          <div className="footer__row footer__socials">
            <SocialIcons />
            <div>
              <h4>Phone us</h4>
              <InfoPhone />
            </div>
            <div>
              <h4>Contact us</h4>
              <InfoEmail />
            </div>
          </div>
        </div>
        <div className="footer__row footer__info">
          <span>
            {/* © site-leeds-holi-festival {new Date().getFullYear()} */}
          </span>
          <div>
            <a
              href="https://fixr.co/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              <span>Privacy Policy</span>
            </a>{" "}
            <span>•</span>{" "}
            <a
              href="https://fixr.co/legal/organiser-terms-and-conditions"
              target="_blank"
              rel="noreferrer"
            >
              <span>Terms & Conditions</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
