import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { useMediaQuery } from "hooks/useMediaQuery";
import { SocialIcons } from "./SocialIcons";
import IconMenu from "../svgs/IconMenu.svg";
import IconCross from "../svgs/IconCross.svg";
import Logo from "../svgs/Logo.svg";

const links = [
  {
    to: "/",
    children: "Home",
  },
  {
    to: "/about/",
    children: "About",
  },
  {
    to: "/#tickets",
    children: "Buy Tickets",
  },
];

export function Header() {
  const { pathname } = useLocation();

  const isLarge = useMediaQuery("(min-width: 800px)");

  const [navOpen, setNavOpen] = useState(false);

  const navRef = useRef();
  const lineRef = useRef();

  const resizeLine = () => {
    if (navRef.current) {
      const activeLink = navRef.current.querySelector("a.active");
      const navRect = navRef.current.getBoundingClientRect();

      if (activeLink) {
        const linkRect = activeLink.getBoundingClientRect();

        lineRef.current.style.width = `${linkRect.width}px`;

        const offsetX = linkRect.x - navRect.x;

        lineRef.current.style.left = `${offsetX}px`;
      }
    }
  };

  useEffect(() => {
    if (isLarge) {
      setNavOpen(false);
    }
  }, [isLarge]);

  return (
    <header className={`header  ${navOpen ? "header--open" : ""}`}>
      <div className="header__content">
        <Link to="/">
          <Logo className="header__logo" />
        </Link>
        <div className="header__nav-wrapper">
          <nav ref={navRef} className="header__nav">
            {links.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                activeClassName="active"
                onClick={() => {
                  setNavOpen(false);
                }}
              >
                {link.children}
              </Link>
            ))}
            <SocialIcons />
            <div ref={lineRef} className="header__nav-line" />
          </nav>
        </div>
        <button className="header-toggle" onClick={() => setNavOpen(!navOpen)}>
          {navOpen ? <IconCross /> : <IconMenu />}
        </button>
      </div>
    </header>
  );
}
