import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;

const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export function Hero({
  children = null,
  color = "",
  carouselImages = [],
  image,
}) {
  const [imageIndex, setImageIndex] = useState(0);
  const [direction, setDirection] = useState(1);

  const isAnimatingRef = useRef(false);

  const isCarousel = carouselImages.length > 1;

  const img = isCarousel ? carouselImages[imageIndex].node : image.node;
  const src = isCarousel ? carouselImages[imageIndex].src : image.src;

  useEffect(() => {
    if (isCarousel) {
      const timeout = setTimeout(() => {
        handleNextPage(1);
      }, 4000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [carouselImages.length, direction, imageIndex]);

  useEffect(() => {
    console.log("bv");
    setImageIndex(0);
  }, [carouselImages.length]);

  function handleNextPage(direction) {
    if (isAnimatingRef.current) {
      return;
    }

    isAnimatingRef.current = true;

    setImageIndex((index) => {
      if (direction > 0) {
        let nextIndex = index + 1;

        if (nextIndex > carouselImages.length - 1) {
          nextIndex = 0;
        }
        return nextIndex;
      } else {
        let nextIndex = index - 1;

        if (0 > nextIndex) {
          nextIndex = carouselImages.length - 1;
        }
        return nextIndex;
      }
    });
    setDirection(direction);
    setTimeout(() => (isAnimatingRef.current = false), 300);
  }

  return (
    <div className={`hero hero--${color}`}>
      {isCarousel ? (
        <div>
          <AnimatePresence initial={false} custom={direction}>
            <motion.img
              key={imageIndex}
              className="hero__img hero__img--animated"
              src={src}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 400, damping: 35 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
                if (swipe < -swipeConfidenceThreshold) {
                  handleNextPage(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  handleNextPage(-1);
                }
              }}
            ></motion.img>
          </AnimatePresence>
        </div>
      ) : (
        <div>{img}</div>
      )}
      <div className="hero__gradient"></div>
      <div className="hero__content">{children}</div>
    </div>
  );
}
