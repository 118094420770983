import React from "react";

export function InfoEmail() {
  return (
    <a href="mailto:info@leedsholifestival.com">info@leedsholifestival.com</a>
  );
}

export function InfoPhone() {
  return <a href="tel:07442129760">07442129760</a>;
}
