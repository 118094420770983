import React from "react";

import IconFacebook from "../svgs/IconFacebook.svg";
import IconInstagram from "../svgs/IconInstagram.svg";

export function SocialIcons({}) {
  return (
    <div className={`social-icons`}>
      <a
        href="https://www.facebook.com/Leeds-Holi-Colour-Festival-100972091831886"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconFacebook />
      </a>
      <a
        href="https://www.instagram.com/leedsholifestival2023/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconInstagram />
      </a>
    </div>
  );
}
